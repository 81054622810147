import { graphql } from 'gatsby'
import React from "react"
import Gallery from '@browniebroke/gatsby-image-gallery'
import '@browniebroke/gatsby-image-gallery/dist/style.css'
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import cover from "../../assets/img/gravure/cover.jpg"
import img1 from "../../assets/img/gravure/img-1.jpg"
import img2 from "../../assets/img/gravure/img-2.jpg"
import img3 from "../../assets/img/gravure/img-3.jpg"

const GravurePage = ({ data }) => {
  const g = data.gallery.edges.map(({ node }) => node.childImageSharp)
  const script = require('../../assets/js/script')
  return(
  <Layout>
    <SEO title="Gravure dans le solid surface" />
   <div class="md:mx-40 md:my-5 mx-3">
      <div class="parent-div">
        <div id="box-shadow-div"></div>
      </div>
      <div class="grid grid-cols-1 mt-8 gap-5">
        <h1 class="text-center text-2xl font-bold uppercase">LA GRAVURE DANS LE SOLID SURFACE</h1>
        <div class="bg-gradient-to-r from-white via-red-700 to-white h-0.5"></div>
      </div> 
          <div class="my-5">  <img src={cover} alt="cover" /> </div>
       
      <div class="grid grid-cols-1 md:grid-cols-2 mt-8 gap-8">
        <div><img src={img1} alt="1" /></div>
        <div><img src={img2} alt="2" /></div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 mt-8 gap-8">
        <div><p class="font-bold mb-2">UN TRAITEMENT PÉRENNE POUR VOTRE MOBILIER</p>
        <div class="border-solid border-2 border-red-500 w-12 mb-3"></div>
          <p class="mt-2"><span class="font-bold">La gravure dans le Solid Surface</span> dite <span class="font-bold">« G3 »</span> permet aux concepteurs d’espace de personnaliser <span class="font-bold">le Solid Surface</span> de manière très innovante.</p>

          <p class="mt-2">Cette technique permet l’obtention d’une finition incroyable, qui permet d’<span class="font-bold">usiner </span>des <span class="font-bold">motifs</span> infimes, des <span class="font-bold">photographies</span>, dans les environnements les plus exigeants.</p>

          <p class="mt-2">Lorsque le <span class="font-bold">rétroéclairage</span> est associé à la gravure dans le Solid Surface, le résultat est particulièrement bluffant : les différentes profondeurs d’usinage génèrent des nuances de lumière, pour créer des images « vivantes ».</p>
         </div>
         <div class="mt-5"><img src={img3} alt="image 3" /></div>
      </div>
      <div class="bg-gradient-to-r from-white via-red-700 to-white h-0.5 my-5"></div>
      <div class="gl mt-5"> <Gallery images={g} /></div>
    </div>
   </Layout>
)
}
export const query = graphql`
query GravureGallery {
  gallery:allFile (filter:{relativeDirectory: {eq: "img/gravure/g"}}){
    edges {
      node {
        childImageSharp {
          thumb: fluid(maxWidth: 270, maxHeight: 270) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
}
`
export default GravurePage